export const en = {
    "home": "HOME",
    "partner" : " Quality is our priority",
    "homeTitle": "We succeed by making quality products that enrich the taste and soul.",
    "home-text1": "Avromak LLC is one of the professional and experienced companies in the production. It is engaged in production, retail and wholesale of various meat and meat products, flour and flour products. ",
    "home-text2": "Our products are produced in completely hygienic environment and by using innovative technologies. Avromak LLC is reaching its goals with a friendly and professional team. Our products have been awarded the AZS 731-2012 Halal Food certificate.",
    "meat": "Meat products",
    "products": "Our products",
    "flour": "Flour products",
    "makaron": "Pasta",
    "brands": "OUR BRANDS",
    "brands-text": "The main goal of Avromak LLC is to optimize the range and quality of the products that it produces and distributes.",
    "choooseUs": "Why choose us?",
    "techno": "TECHNOLOGY",
    "technoText": "Products are produced in completely hygienic environment and using innovative technologies.",
    "exper": "PROFESSIONAL TEAM",
    "experText": "Avromak LLC is reaching its goals with a friendly and professional team.",
    "quality": "QUALITY",
    "qualityText": "Our products have been awarded the AZS 731-2012 Halal Food certificate.",
    "catalog": "Product catalog",
    "catalogText": "You can download our catalog to get more information about our company and products.",
    "download": "Download",
    "aboutTitle": "About us",
    "aboutDesc": "Premium first-class wheat from 12,000 hectares of private land in Aghjabedi and Bilasuvar is processed in our factory in Baku and delivered to our customers.",
    "aboutDesc1": "“Avromak” LLC started operating in Baku, Azerbaijan in 2019. The company's activity is based on the sale of the products of its own production areas, as well as those of the companies it distributes. The company delivers products to any region of Azerbaijan with its workforce and vehicles.",
    "aboutDesc2": "The main goal of “Avromak” LLC is to optimize the range and quality of the products it manufactures and distributes, and it constantly works in this direction. “Avromak” LLC consists of head office located in Baku, warehouses, mill, production areas and regional branches. The company cooperates with different countries of the world and is expanding these cooperations.",
    "vision": "Our vision",
    "visionText1": "To be an innovative company that continuously creates value for all our partners and customers.",
    "visionText2": "Also to provide high-quality service to all sales points that currently exist in our republic, to gain customer sympathy, and to use the latest and the most modern tools in this way.",
    "mission": "Our mission",
    "missionText1": "To be a lasting taste in people's palates and their minds.",
    "missionText2": "Our first priority is to listen to our customers and employees and ensure their satisfaction by solving problems as soon as possible.",
    "goal": "Our goal",
    "goalText1": "Our main goal is to provide high-quality sales service to all trade points in the Azerbaijan market, to gain our customers satisfaction and to use the latest and most modern tools in this direction.",
    "goalText2": "Within the framework of our activities, we work with foreign manufacturers at the distribution level. The fact that our company has a modern warehouse infrastructure and carries out the distribution of more than 100 leading manufacturers of the world constantly gives impetus to our development.",
    "aboutText": "By maintaining the natural balance, we produce eco-friendly, sustainable agricultural products and focus on healthy food supply.",
    "aboutText1": "As Avromak LLC, we have been operating with our agricultural products, which derive their strength from the soil, since our foundation. When we make our investments, we do with a strategy that is environmentally sustainable, socially responsible and supports economic growth.  Globalized agricultural policies increase interest in grain production and emphasize monoculture in agricultural production. Along with changing production, changing income levels and fast urbanization add different dimensions to the demand for agricultural products, from eating habits to food supply.",
    "prod": "Production",
    "prodText": "From day of establishment, Avromak LLC has gained a wide reputation in both the domestic and foreign markets because of quality products and delivery on time.",
    "prodText1": "As Avromak LLC, we have been operating with our agricultural products, which derive their strength from the soil, since our foundation. When we make our investments, we do with a strategy that is environmentally sustainable, socially responsible and supports economic growth. The wheat products obtained from our fields are milled in our factory and pasta and flour products are obtained from them. Our highly qualified employees have great efforts in bringing our products to the markets. The production equipment is entirely made in Italy. Globalized agricultural policies increase interest in grain production and emphasize monoculture in agricultural production. Along with changing production, changing income levels and fast urbanization add different dimensions to the demand for agricultural products, from eating habits to food supply. ",
    "area1": "PLANTING FIELD",
    "area2": "PRODUCTION FIELD",
    "privateLabel": "Private label",
    "privateLabelText": "Our quality, healthy, tasty pasta and flour in particular are prepared in accordance with the standards and specifications of our customers and are produced by our professional employees under the guarantee of Avromak LLC. We have extensive experience in the development of Private Label. If you have your own brand, we can provide any package structure under your brand. Even if you don't have a brand under the agreement, we can design your own brand to meet your requirements. You can contact us about the Private Label project. As a supplier, we are always ready to develop your private label.",
    "retail": "Retail",
    "retailTitle": "Rublyovski stores chain of AVROMAK LLC company",
    "retailText1": "The company's activity is based on the sale of the products of its own production areas, as well as those of the companies it distributes. It is engaged in the production, retail and wholesale of various meat and meat products, flour and flour products.",
    "retailText2": "Rublyovski catalog ",
    "retailText3": "You can download the catalog to get more information about RUBLYOVSKI.",
    "branches": "Our stores",
    "branch1": "62 Bakikhanov street,  7/24",
    "branch2": " Phone: +994 51 229 97 41",
    "branch3": "Email : rublyovskiyazerbaijan@gmail.com",
    "branch4": "Mirasadulla Mirgasimov street 15",
    "branch5": "Phone: +994 51 229 97 41",
    "branch6": "Email : rublyovskiyazerbaijan@gmail.com",
    "branch7": "Badamdar rode (Opposite Bazarstore)",
    "branch8": "Phone: +994 51 229 97 41",
    "branch9": "Email : rublyovskiyazerbaijan@gmail.com",
  
    "export": "Export",
    "exportTitle": "Avromak has gained its reputation both domestically and internationally due to its principles of quality, product and delivery on time. ",
    "exportText1": "About export",
    "exportText2": "Avromak LLC is one of Azerbaijan's leading companies producing and exporting pasta, flour and meat products. Since its inception, Avromak has gained its reputation both domestically and internationally due to its principles of quality, product and delivery on time. Our company sells 90% of its products in export markets under its brands Favelli, Kelebek and Makara. A strong, young and dynamic export team participates in many exhibitions and events around the world and continues its activities in accordance with the goals and objectives of Avromak brands. ",
    "exportText3": "Avromak LLC exports to many countries such as Jordan, Iran, Iraq, UAE, Syria, Nigeria, Libya, Indonesia, Malaysia, Thailand, Korea and Georgia. The company continues its activities in order to improve its export network in the future. Avromak LLC always fulfill its obligations undertaken to its customers in full and on time.",
    "exportForm": "Export form",
    "exportForm1": "You can contact us by sending an e-mail to export@avromak.az or using the contact form to get a price and more information about our products and services.",
    "form1": "First name",
    "form2": "Last name",
    "form3": "Email ",
    "form4": "Country",
    "form5": "Brand",
    "form6": "Packaging",
    "form7": "Your message",
    "send": "Send",
    "contactUs": "Contact us",
    "contact1": "Full Name",
    "contact2": "Email",
    "contact3": "Your Message",
    "contact4": "Address",
    "contact5": "Phone number",
  
    "hours": "Work hours",
    "week": "Mon – Fri",
    "phone": "Phone",
  
    "contact6": "Ziya Bunyadov ave. 13,",
  
    "contact7": "Baku, Azerbaijan AZ1108",
    "contact8": "Email",
    
  
    "meat1": "Production of meat products of AVROMAK LLC company",
    "meat2": "Sausage Production Department is fully automated and our process equipment is imported from Germany, Italy and Poland. Our activity is based on the principles of halal, truthfulness, ecological naturalness, constant development, transparency and responsibility. Our main philosophy is to carefully evaluate the demand of buyers and to create the highest quality products according to these demand.",
  
    "favelli1": "Ingredients",
    "favelli2": "Flour made from hard wheat and water. Doesn’t contain preservative, coloring or other food additives.",
    "favelli3": "Storage condition",
    "favelli4": "To be stored in a clean and dry place at a temperature not exceeding 30°C, relative humidity not exceeding 75%. Protect from foul-smelling environments and prevent pest infestations.",
    "favelli5": "Shelf life",
    "favelli6": "24 months",
    "favelli7": "Nutritional and energy values of 100 g of product:",
    "favelli8": "Fat",
    "favelli9": "Protein",
    "favelli10": "Carbohydrate",
    "favelli11": "Energy value (KCAL)",
    "Products": "products",
   
    "makara1": "Flour made from hard wheat and water. Doesn’t contain preservative, coloring or other food additives.",
  
    "makara2": "To be stored in a clean and dry place at a temperature not exceeding 30°C, relative humidity not exceeding 75%. Protect from foul-smelling environments and prevent pest infestations.",
    "makara3": "24 months",
  
    "butterfly1": "Flour made from hard wheat and water. Doesn’t contain preservative, coloring or other food additives.",
  
    "butterfly2": "To be stored in a clean and dry place at a temperature not exceeding 30°C, relative humidity not exceeding 75%. Protect from foul-smelling environments and prevent pest infestations.",
    "butterfly3": "24 months",
  
    "rizzi1": "Flour made from hard wheat and water. Doesn’t contain preservative, coloring or other food additives.",
  
    "rizzi2": "To be stored in a clean and dry place at a temperature not exceeding 30°C, relative humidity not exceeding 75%. Protect from foul-smelling environments and prevent pest infestations.",
    "rizzi3": "24 months",
    "about": "About us",
    "copy": "2023 Avromak LLC  |  All rights reserved",
    "copy2": "Design and development by Martian Studio"
  }
  
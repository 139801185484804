export const az = {
    "home": "ANA SƏHİFƏ",
    "partner" : " Keyfiyyət rəmzimizdir",
    "homeTitle": "Zövqü və ruhu zənginləşdirən keyfiyyətli məhsul hazırlamaqla uğur qazanırıq",
    "home-text1": "Avromak MMC istehsal sahəsində peşəkar və təcrübəyə malik şirkətlərdən biridir. Çeşidli ət və ət məhsulları, un və un məhsullarının istehsalı, pərakəndə və topdan satışı ilə məşğuldur. ",
    "home-text2": "Məhsullarımız, tam gigiyenik şəraitdə və innovativ texnologiyalar vasitələr ilə istehsal olunur. Mehriban və peşəkar komanda ilə Avromak MMC qoyduğu hədəflərə çatmaqdadır. Məhsullarımız AZS 731-2012 “Halal Qida” sertifkatına layiq görülmüşdür.",
    "meat": "Ət məhsulları",
    "products": "Məhsullarımız",
    "flour": "Un məhsulları",
    "makaron": "Makaron",
    "brands": "BRENDLƏR",
    "brands-text": "“Avromak” MMC-nin əsas məqsədi istehsal və distribüterlik etdiyi məhsulların çeşidlərinin və keyfiyyətinin optimallaşdırılmasıdır.",
    "choooseUs": "Niyə bizi seçməlisiniz?",
    "techno": "TEXNOLOGİYA",
    "technoText": "Məhsullar tam gigiyenik şəraitdə və innovativ texnologiyalar vasitələr ilə istehsal olunur.",
    "exper": "PEŞƏKARLIQ",
    "experText": "Mehriban və peşəkar komanda ilə “Avromak” MMC qoyduğu hədəflərə çatmaqdadır.",
    "quality": "KEYFİYYƏT",
    "qualityText": "Məhsullarımız AZS 731-2012 “Halal Qida” sertifkatına layiq görülmüşdür.",
    "catalog": "Məhsul kataloqu",
    "catalogText": "Şirkətimiz və məhsullarımız haqqında ətraflı məlumat əldə etmək üçün kataloqumuzu yükləyə bilərsiniz.",
    "download": "Kataloqu yüklə",
    "aboutTitle": "Haqqımızda",
    "aboutDesc": "Ağcabədi və Biləsuvar`daki 12000 hektar şəxsi ərazidən gələn Premium birinci sinif buğdalar Bakı`daki fabrikamızda işlənərək müştərilərimizə çatdırılır.",
    "aboutDesc1": "“Avromak” MMC 2019-cu ildə Azərbaycanda, Bakı şəhərində fəaliyyətə başlamışdır. Şirkətin fəaliyyəti, öz istehsal sahələrinin, həmçinin distribüterlik etdiyi şirkətlərin məhsullarının satışını təmin etməyə əsaslanır. Şirkət öz işçi qüvvəsi və nəqliyyat vasitələri ilə məhsulları Azərbaycanın istənilən bölgəsinə çatdırmaqdadır.",
    "aboutDesc2": "“Avromak” MMC-nin əsas məqsədi istehsal və distribüterlik etdiyi məhsulların çeşidlərinin və keyfiyyətinin optimallaşdırılmasıdır və bu istiqamətdə daimi çalışır. “Avromak” MMC Bakıda yerləşən baş ofis, anbarlar, dəyirman, istehsal sahələri və regional filiallardan ibarətdir. Şirkət dünyanın müxtəlif ölkələri ilə əməkdaşlıq edir və bu əməkdaşlıqları genişləndirməkdədir.",
    "vision": "Vizyonumuz",
    "visionText1": "Bütün tərəfdaşlarımız və müştərilərimiz üçün davamlı olaraq dəyər yaradan yenilikçi bir şirkət olmaq",
    "visionText2": "Həmçinin hazırda respublikamızda mövcud olan bütün satış nöqtələrinə yüksək keyfiyyətli xidmət göstərmək, alıcı rəğbəti qazanmaq, bu yolda ən son və ən müasir vasitələrdən istifadə etməkdir.",
    "mission": "Misyamız",
    "missionText1": "İnsanların damaqında və şüurunda qalıcı bir dad olmaq",
    "missionText2": "Müştərilərimizi və əməkdaşlarımızı dinləyərək yaranan problemləri ən qısa zamanda həll etməklə məmnuniyyətlərini təmin etmək ilk işimizdir.",
    "goal": "Məqsədimiz",
    "goalText1": "Azərbaycan bazarında mövcud olan bütün ticarət nöqtələrinə yüksək keyfiyyətli satış xidməti göstərmək, alıcı rəğbətini qazanmaq, bu yolda ən son və ən müasir vasitələrdən istifadə etmək əsas məqsədimizdir.",
    "goalText2": "Fəaliyyətimiz çərçivəsində əməkdaşlıq etdiyimiz xarici istehsalçılarla distribüterlik səviyyəsində çalışırıq. Şirkətimizin müasir anbar infrastrukturuna malik olması və dünyanın 100-dən çox aparıcı istehsalçılarının distribüterliyini həyata keçirməsi inkişafımıza daima təkan verir.",
    "aboutText": "Təbii tarazlığı qorumaqla biz ekoloji cəhətdən təmiz, dayanıqlı kənd təsərrüfatı məhsulları istehsal edir və sağlam qida təminatına önəm veririk.",
    "aboutText1": "“Avromak” MMC 2019-cu ildə Azərbaycanda, Bakı şəhərində fəaliyyətə başlamışdır. Şirkətin fəaliyyəti, öz istehsal sahələrinin, həmçinin distribüterlik etdiyi şirkətlərin məhsullarının satışını təmin etməyə əsaslanır. Şirkət öz işçi qüvvəsi və nəqliyyat vasitələri ilə məhsulları Azərbaycanın istənilən bölgəsinə çatdırmaqdadır.",
    "prod": "İstehsalat",
    "prodText": "Avromak şirkəti yarandığı gündən keyfiyyətli məhsul və məhsulların vaxtında ünvana çatdırılma prinsipleri əsasında həm yerli həm də xarici bazarda geniş nüfuz qazanmışdır.",
    "prodText1": "Avromak MMC olaraq, qurulduğumuz gündən gücünü torpaqdan alan kənd təsərrüfatı məhsullarımızla fəaliyyət göstəririk. İnvestisiyalarımızı edərkən, biz bunu ekoloji cəhətdən davamlı, sosial cəhətdən məsuliyyətli və iqtisadi artımı dəstəkləyən bir strategiya ilə edirik. Sahelerimizden elde olunan bugda mehsullari Fabrikimizde uyudulerek onlardan Un ve Makaron yeyinti mehsullari elde olunur. Un ve Makaron Mehsularimizin erseye getirilmesinde yuksek ixtisasli emekdashlarimizin boyuk emeyi vardir. Istehsalat avadanliqlari butunlukle Italiya istehsalidir. Qloballaşan kənd təsərrüfatı siyasəti taxıl istehsalına marağı artırır və kənd təsərrüfatı istehsalında monokulturaya önəm verir. İstehsalın dəyişməsi ilə yanaşı, gəlir səviyyəsinin dəyişməsi və sürətli urbanizasiya kənd təsərrüfatı məhsullarına olan tələbata qidalanma vərdişlərindən tutmuş ərzaq təminatına qədər fərqli ölçülər əlavə edir.",
    "area1": "ƏKİN SAHƏSİ",
    "area2": "İSTEHSAL SAHƏSİ",
    "privateLabel": "Private label",
    "privateLabelText": "Keyfiyyətli, sağlam, ləzzətli makaron və unlarımız müştərilərimizin tələb etdiyi standartlara və spesifikasiyalara uyğun hazırlanır və “Avromak” MMC-nin zəmanəti ilə peşəkar işçilərimiz tərəfindən istehsal olunur. Biz Private Label hazırlamaqda böyük təcrübəyə malikik. Öz markanız varsa, markanız altında istənilən paket quruluşunu təmin edə bilərik. Razılaşmaya görə brendiniz olmasa belə, tələblərinizə uyğun olaraq öz brendinizi hazırlaya bilərik. Private Label layihəsi ilə bağlı istənilən vaxt bizimlə əlaqə saxlaya bilərsiniz. Təchizatçı olaraq biz hər zaman şəxsi etiketinizi hazırlamağa hazırıq.",
    "retail": "Retail",
    "retailTitle": "“AVROMAK MMC” şirkətinin Rublyovski mağazalar şəbəkəsi",
    "retailText1": "Şirkətin fəaliyyəti, öz istehsal sahələrinin, həmçinin distribüterlik etdiyi şirkətlərin məhsullarının satışını təmin etməyə əsaslanır. Çeşidli ət və ət məhsulları, un və un məhsullarının istehsalı, pərakəndə və topdan satışı ilə məşğuldur.",
    "retailText2": "RUBLYOVSKİ KATALOQU",
    "retailText3": "“RUBLYOVSKİ” haqqında ətraflı məlumat əldə etmək üçün kataloqu yükləyə bilərsiniz.",
    "branches": "Mağazalarımız",
    "branch1": "62 Bakıxanov küçəsi, 7/24",
    "branch2": "Əlaqə : +994 51 229 97 41",
    "branch3": "Email : rublyovskiyazerbaijan@gmail.com",
    "branch4": "Miresedulla Mirqasimov küç 15",
    "branch5": "Əlaqə : +994 51 229 97 41",
    "branch6": "Email : rublyovskiyazerbaijan@gmail.com",
    "branch7": "Badamdar şosesi (Bazarstore ilə üzbəüz)",
    "branch8": "Əlaqə : +994 51 229 97 41",
    "branch9": "Email : rublyovskiyazerbaijan@gmail.com",
  
    "export": "İxrac",
    "exportTitle": "Avromak yarandığı gündən keyfiyyət, məhsul və vaxtında çatdırılma prinsipləri sayəsində həm yərli həm də xarici miqyasda nüfuzunu qazanmışdır.",
    "exportText1": "Export haqqında",
    "exportText2": "Avromak MMC Azərbaycan`ın qabaqcıl makaron,un və ət məhsulları istehsalı və ixracı eləyən şirkətlərindən biridir. Avromak yarandığı gündən keyfiyyət, məhsul və vaxtında çatdırılma prinsipləri sayəsində həm yərli həm də xarici miqyasda nüfuzunu qazanmışdır. Şirkətimiz istehsal etdiyi məhsulun 90%-ni öz brendləri olan Favelli, Kələbək və Makara ilə ixrac bazarlarında satır. Güclü, gənc və dinamik ixracat komandası dünyanın bir çox sərgi və tədbirlərində iştirak edərək Avromak markalarının məqsəd və hədəflərinə uyğun olaraq fəaliyyətini davam etdirir.",
    "exportText3": "Avromak-ın ixrac etdiyi ölkələr arasında İordaniya, İran, İraq, BƏƏ, Suriya, Nigeriya, Liviya, İndoneziya, Malayziya, Tayland, Koreya və Gürcüstan var. Şirkət gələcəkdə ixrac şəbəkəsini təkmilləşdirmək üçün işlərini davam etdirir. Avromak, müştəriləri qarşısında götürdüyü öhdəlikləri hər zaman tam və vaxtında yerinə yetirəcəyini öhdəsinə götürür.",
    "exportForm": "Export formu",
    "exportForm1": "Qiymət almaq və məhsul və xidmətlərimiz haqqında daha ətraflı məlumat almaq üçün export@avromak.az ünvanına e-poçt göndərməklə və ya əlaqə formasından istifadə etməklə bizimlə əlaqə saxlaya bilərsiniz.",
    
    "form1": "Ad",
    "form2": "Soyad",
    "form3": "Email ",
    "form4": "Ölkə",
    "form5": "Marka",
    "form6": "Qablaşdırma",
    "form7": "Mesajınız",
  
    "send": "Göndər",
    "contactUs": "Bizimlə əlaqə",
    "contact1": "Ad və Soyad",
    "contact2": "Email",
    "contact3": "Mesajınız",
    "contact4": "Ünvan",
    "contact5": "Əlaqə",
  
    "hours": "İş vaxtı",
    "week": "Be – Cm",
    "phone": "Telefon",
  
    "contact6": "Ziya Bünyadov pr. 13,",
    "contact7": "Bakı, Azərbaycan AZ1108",
  
    "contact8": "Email",
  
    "meat1": "“AVROMAK MMC” şirkətinin ət məhsulları istehsalatı",
    "meat2": "Kolbasa İstehsal bolumunde Istehsal tam olaraq avtomatlaşdırılıb və prosesə daxil olan avadanlıqlarımız Almaniya, İtaliya və Polşadan gətirilib. Fəaliyyətimiz halallıq, düzgünlük, ekoloji təbiilik, daimi inkişaf, şəffaflıq və məsuliyyətlilik prinsipləri üzərində qurulub. Əsas fəlsəfəmiz isə alıcıların istəklərini diqqətlə dəyərləndirmək və bu istəklərə uyğun ən yüksək keyfiyyətdə məhsul ərsəyə gətirməkdir.",
  
    "favelli1": "Tərkibi",
    "favelli2": "Bərk buğda növündən hazırlanmış un və su. Tərkibində konservant, rəngləyici və digər qida əlavələri yoxdur.",
    "favelli3": "Saxlanılma şəraiti",
    "favelli4": "30°C-dən çox olmayan temperaturda, havanın nisbi rütubəti 75%-dən çox olmayan, təmiz və quru yerdə saxlanılmalıdır. Kənar qoxulu və ziyanvericilərlə yoluxmuş mühitdən qoruyun.",
    "favelli5": "Saxlama müddəti",
    "favelli6": "24 ay",
    "favelli7": "100 qr məhsulun qida və enerji dəyərləri",
    "favelli8": "Yağlar",
    "favelli9": "Zülallar",
    "favelli10": "Karbohidratlar",
    "favelli11": "Enerji dəyəri (KKAL)",
    "Products": "məhsulları",
  
   
    "makara1": "Bərk buğda növündən hazırlanmış un və su. Tərkibində konservant, rəngləyici və digər qida əlavələri yoxdur.",
  
    "makara2": "30°C-dən çox olmayan temperaturda, havanın nisbi rütubəti 75%-dən çox olmayan, təmiz və quru yerdə saxlanılmalıdır. Kənar qoxulu və ziyanvericilərlə yoluxmuş mühitdən qoruyun.",
    "makara3": "24 ay",
  
    "butterfly1": "Bərk buğda növündən hazırlanmış un və su. Tərkibində konservant, rəngləyici və digər qida əlavələri yoxdur.",
  
    "butterfly2": "30°C-dən çox olmayan temperaturda, havanın nisbi rütubəti 75%-dən çox olmayan, təmiz və quru yerdə saxlanılmalıdır. Kənar qoxulu və ziyanvericilərlə yoluxmuş mühitdən qoruyun.",
    "butterfly3": "24 ay",
  
    "rizzi1": "Bərk buğda növündən hazırlanmış un və su. Tərkibində konservant, rəngləyici və digər qida əlavələri yoxdur.",
  
    "rizzi2": "30°C-dən çox olmayan temperaturda, havanın nisbi rütubəti 75%-dən çox olmayan, təmiz və quru yerdə saxlanılmalıdır. Kənar qoxulu və ziyanvericilərlə yoluxmuş mühitdən qoruyun.",
    "rizzi3": "24 ay",
    "about": "Haqqımızda",
    "copy": "2022 Avromak MMC  |  Bütün hüquqlar qorunur",
    "copy2": "Design and development by Martian Studio"
  }
  

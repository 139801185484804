export const ru = {
    "home": "HOME",
    "partner" : " Качество - наш приоритет",
    "homeTitle": "Мы добиваемся успеха, делая качественные продукты, которые обогащают вкус и душу",
    "home-text1": "Наша продукция производится в полностью гигиенической среде и с использованием инновационных технологий. ООО «Авромак» добивается поставленных целей дружным и профессиональным коллективом. Наша продукция удостоена сертификата AZS 731-2012 «Halal Food».",
    "home-text2": "",
    "meat": "Мясопродукты",
    "products": "Наши продукты",
    "flour": "Мучные изделия",
    "makaron": "Макароны",
    "brands": "Бренды",
    "brands-text": "Основной целью ООО «Авромак» является оптимизация ассортимента и качества выпускаемой и распространяемой им продукции.",
    "choooseUs": "Зачем ВЫБИРАТЬ НАС?",
    "techno": "технология",
    "technoText": "Продукция производится в полностью гигиенической среде и с использованием инновационных технологий.",
    "exper": "ПРОФЕСИОНАЛИЗМ",
    "experText": "ООО «Авромак» добивается поставленных целей дружным и профессиональным коллективом.",
    "quality": "КАЧЕСТВО",
    "qualityText": "Наша продукция удостоена сертификата AZS 731-2012 «Халяль Фуд».",
    "catalog": "Каталог продуктов",
    "catalogText": "Вы можете загрузить наш каталог, чтобы получить дополнительную информацию о нашей компании и продуктах.",
    "download": "Скачать каталог",
    "aboutTitle": "О нас",
    "aboutDesc": "Пшеница первого класса с 12 000 га частной земли в Агджабеди и Биласуваре перерабатывается на нашем заводе в Баку и поставляется нашим клиентам.",
    "aboutDesc1": "ООО «Авромак» начало свою деятельность в Баку, Азербайджан, в 2019 году. Деятельность компании основана на реализации продукции собственных производственных площадей.Компания поставляет продукцию в любой регион Азербайджана со своей рабочей силой и транспортными средствами.",
    "aboutDesc2": "Основной целью ООО «Авромак» является оптимизация ассортимента и качества выпускаемой и распространяемой им продукции, и оно постоянно работает в этом направлении. ООО «Авромак» состоит из головного офиса, расположенного в Баку, складов, комбината, производственных участков и региональных филиалов. ",
    "vision": "Наше видение",
    "visionText1": "Быть инновационной компанией, которая постоянно создает ценность для всех наших партнеров и клиентов.",
    "visionText2": "Также обеспечить качественное обслуживание всех точек продаж, которые сейчас существуют в нашей республике, завоевать симпатии клиентов, и использовать новейшие и самые современные инструменты.",
    "mission": "Наша миссия",
    "missionText1": "Чтобы быть постоянным вкусом в вкусе людей и их умах.",
    "missionText2": "Нашим главным приоритетом является прислушиваться к нашим клиентам и сотрудникам и обеспечивать их удовлетворение.",
    "goal": "Наша цель",
    "goalText1": "Наша главная цель - обеспечить качественное обслуживание всех торговых точек на азербайджанском рынке, получить удовлетворение наших клиентов и использовать новейшие и самые современные инструменты в этом направлении.",
    "goalText2": "В рамках нашей деятельности мы работаем с зарубежными производителями на уровне дистрибуции. То, что наша компания имеет современную складскую инфраструктуру и осуществляет дистрибуцию более 100 ведущих производителей мира, постоянно дает толчок нашему развитию.",
    "aboutText": "Поддерживая естественный баланс, мы производим экологически чистую, устойчивую сельскохозяйственную продукцию и ориентируемся на здоровое продовольствие.",
    "aboutText1": "Как ООО «Авромак», мы работаем со своей сельскохозяйственной продукцией, которая извлекает свою силу из почвы, с момента основания. Когда мы делаем свои инвестиции, мы делаем со стратегией, которая является экологически устойчивой, социально ответственной и поддерживает экономический рост. Глобализованная сельскохозяйственная политика повышает интерес к производству зерна и делает упор на монокультуру в сельскохозяйственном производстве. Наряду с изменением производства изменение уровня доходов и быстрая урбанизация придают различные аспекты спросу на сельскохозяйственную продукцию - от привычек питания до предложения продовольствия.",
    "prod": "Производство",
    "prodText": "Со дня своего создания ООО «Авромак» получило широкую репутацию как на внутреннем, так и на внешнем рынке благодаря качественной продукции",
    "prodText1": "Как ООО «Авромак», мы работаем со своей сельскохозяйственной продукцией, которая извлекает свою силу из почвы, с момента основания. Мы делаем свои инвестиции  со стратегией, которая является экологически устойчивой, социально ответственной и поддерживает экономический рост. Продукты пшеницы, полученные с наших полей, измельчаются на нашем заводе и получают из них макароны и мучные изделия. Наши высококвалифицированные сотрудники прилагают большие усилия для вывода нашей продукции на рынки. Производственное оборудование полностью изготовлено в Италии. Глобализованная сельскохозяйственная политика повышает интерес к производству зерна и делает упор на монокультуру в сельскохозяйственном производстве. Наряду с изменением производства изменение уровня доходов и быстрая урбанизация придают различные аспекты спросу на сельскохозяйственную продукцию - от привычек питания до предложения продовольствия. ",
    "area1": "ПОСЕВНАЯ ПЛОЩАДЬ",
    "area2": "ПРОИЗВОДСТВЕННАЯ ПЛОЩАДЬ",
    "privateLabel": "Частная торговая марка",
    "privateLabelText": "Наши качественные, здоровые, вкусные макароны и мука готовятся в соответствии со стандартами и спецификациями наших клиентов и производятся нашими профессиональными сотрудниками под гарантией ООО «Авромак». У нас большой опыт в разработке Private Label. Если у вас есть собственный бренд, мы можем предоставить любую структуру пакета под вашим брендом. Даже если у вас нет бренда в соответствии с соглашением, мы можем спроектировать ваш собственный бренд в соответствии с вашими требованиями. Вы можете связаться с нами по поводу проекта частная торговая марка. Как поставщик, мы всегда готовы разработать вашу частную этикетку.",
    "retail": "Ритейл",
    "retailTitle": "Сеть магазинов «Рублёвский» компании ООО «АВРОМАК»",
    "retailText1": "Деятельность компании основана на реализации  собственных продукции. Также «АВРОМАК» занимается производством, розничной и оптовой продажей различного мяса и мясопродуктов, муки и мучных изделий.",
    "retailText2": "РУБЛЁВСКИЙ КАТАЛОГ",
    "retailText3": "Чтобы получить больше информации о РУБЛЕВСКИЙ- скачайте каталог.",
    "branches": "Наши магазины",
    "branch1": "ул. Бакиханова, 62, 7/24",
    "branch2": "Телефон: +994 51 229 97 41",
    "branch3": "Е-мейл: rublyovskiyazerbaijan@gmail.com",
    "branch4": "ул. Миреседуллы Миргасимова 15 ",
    "branch5": "Телефон: +994 51 229 97 41",
    "branch6": "Е-мейл: rublyovskiyazerbaijan@gmail.com",
    "branch7": "Badamdar rode (Opposite Bazarstore)",
    "branch8": "Телефон: +994 51 229 97 41",
    "branch9": "Е-мейл: rublyovskiyazerbaijan@gmail.com",
  
    "export": "Экспорт",
    "exportTitle": "Avromak получил репутацию как внутри страны, так и за рубежом благодаря своим принципам качества, качества продукции и своевременности поставок.",
    "exportText1": "Об экспорте",
    "exportText2": "ООО «Авромак» - одна из ведущих азербайджанских компаний по производству и экспорту макаронных изделий, муки и мясопродуктов. С момента своего создания компания Avromak приобрела репутацию как внутри страны, так и за рубежом благодаря своим принципам качества, качества продукции и своевременности поставок. Наша компания продает 90% своей продукции на экспортных рынках под брендами Favelli, Kelebek и Makara.  ",
    "exportText3": "ООО «Авромак» экспортирует во многие страны, такие как Иордания, Иран, Ирак, ОАЭ, Сирия, Нигерия, Ливия, Индонезия, Малайзия, Таиланд, Корея и Грузия. Компания продолжает свою деятельность с целью улучшения экспортной сети в будущем. ООО «Авромак» всегда выполняет свои обязательства перед клиентами в полном объеме и в срок.",
    "exportForm": "Анкета по экспорту",
    "exportForm1": "Вы можете связаться с нами, отправив электронное письмо на адрес export@avromak.az или используя контактную форму, чтобы получить цену и дополнительную информацию о наших продуктах и услугах.",
    "form1": "Имя",
    "form2": "Фамилия",
    "form3": "Е-мейл",
    "form4": "Страна",
    "form5": "Марка",
    "form6": "упаковка",
    "form7": "Ваше сообщение",
    "send": "Отправить",
    "contactUs": "Kонтакты",
    "contact1": "Наши контакты",
    "contact2": "Электронная почта",
    "contact3": "Ваше сообщение",
    "contact4": "Адрес",
    "contact5": "Телефон",
   
  
    "hours": "Рабочие часы",
    "week": "Пон. – Пят.",
    "phone": "Телефон",
  
    "contact6": "пр.Зия Буньядова 13,",
  
    "contact7": "Баку, Азербайджан AZ1108",
  
    "contact8": "Е-мейл",
  
    "meat1": "Производство мясной продукции компании ООО «АВРОМАК»",
    "meat2": "Производства колбасы полностью автоматизирован,  технологическое оборудование импортируется из Германии, Италии и Польши. Наша деятельность основана на принципах халяльности, правдивости, экологической естественности.Наша основная философия заключается в том, чтобы тщательно оценивать спрос покупателей и создавать продукты высочайшего качества.",
  
    "favelli1": "Состав",
    "favelli2": "Мука из твердой пшеницы и воды. Не содержит консервантов, красителей или других пищевых добавок.",
    "favelli3": "Условия хранения",
    "favelli4": "Хранить в сухом и чистом месте при температуре не выше 30°C, относительной влажности не более 75%. Хранить подальше от дурно пахнущих веществ и предотвращать заражение вредителями. ",
    "favelli5": "Срок годности",
    "favelli6": "24 месяца",
    "favelli7": "Пищевая и энергетическая ценность на 100 г продукта:",
    "favelli8": "Жиры",
    "favelli9": "Белки",
    "favelli10": "Углеводы",
    "favelli11": "Энергетическая ценность (ккал)",
    "Products": "Макароны ",
    "makara1": "Мука из твердой пшеницы и воды. Не содержит консервантов, красителей или других пищевых добавок.",
  
    "makara2": "Хранить в сухом и чистом месте при температуре не выше 30°C, относительной влажности не более 75%. Хранить подальше от дурно пахнущих веществ и предотвращать заражение вредителями.",
    "makara3": "24 месяца",
  
    "butterfly1": "Мука из твердой пшеницы и воды. Не содержит консервантов, красителей или других пищевых добавок.",
  
    "butterfly2": "Хранить в сухом и чистом месте при температуре не выше 30°C, относительной влажности не более 75%. Хранить подальше от дурно пахнущих веществ и предотвращать заражение вредителями.",
    "butterfly3": "24 months",
  
    "rizzi1": "Мука из твердой пшеницы и воды. Не содержит консервантов, красителей или других пищевых добавок.",
  
    "rizzi2": "To be stored in a clean and dry place at a temperature not exceeding 30°C, relative humidity not exceeding 75%. Protect from foul-smelling environments and prevent pest infestations.",
    "rizzi3": "24 месяца",
    "about": "О нас",
    "copy": "2022 ООО «Авромак» | Все права защищены",
    "copy2": "Дизайн и разработка Martian Studio"
  }
  

export default {
    SET_LANG: 'SET_LANG',
    GET_LANG: 'GET_LANG',

    GET_SUBJECT: 'GET_SUBJECT',
    GET_SUBJECT_ERROR: 'GET_SUBJECT_ERROR',

    GET_PARTNERS: 'GET_PARTNERS',
    GET_PARTNERS_ERROR: 'GET_PARTNERS_ERROR',

    GET_CONTACT: 'GET_CONTACT',
    GET_CONTACT_ERROR: 'GET_CONTACT_ERROR',

    GET_SOCIALS: 'GET_SOCIALS',
    GET_SOCIALS_ERROR: 'GET_SOCIALS_ERROR',

    GET_ABOUT: "GET_ABOUT",
    GET_ABOUT_ERROR: "GET_ABOUT_ERROR",

    // GET_SUPPORT: "GET_SUPPORT",
    // GET_SUPPORT_ERROR: "GET_SUPPORT_ERROR",

    GET_SUPPORT_FULL: "GET_SUPPORT_FULL",
    GET_SUPPORT_FULL_ERROR: "GET_SUPPORT_FULL_ERROR",

    GET_NEWS_FULL: "GET_NEWS_FULL",
    GET_NEWS_FULL_ERROR: "GET_NEWS_FULL_ERROR",

    GET_WORKERS: "GET_WORKERS",
    GET_WORKERS_ERROR: "GET_WORKERS_ERROR",

    GET_WORKERS_FULL: "GET_WORKERS_FULL",
    GET_WORKERS_FULL_ERROR: "GET_WORKERS_FULL_ERROR",

    GET_YEARS: "GET_YEARS",
    GET_YEARS_ERROR: "GET_YEARS_ERROR",

    GET_FREE_SUPPORT_FULL: "GET_FREE_SUPPORT_FULL",
    GET_FREE_SUPPORT_FULL_ERROR: "GET_FREE_SUPPORT_FULL_ERROR",

}
